import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import ReactMarkdown from "react-markdown";
import { SEO } from '../components'
import ContentLayout from "../components/layouts/wide-content"
import HTMLContent from "../components/general/html-content";
import MarkdownToHtml from "../components/general/MarkdownToHtml"
import "../assets/styles/components/faq-page.scss";


export const FaqPageTemplate = props => {
  const { page } = props;
  const data = page.frontmatter;

  const pageData = {
    title: data.title,
    description: data.subtitle
  }

  return (
    <main >
      <title>{data.title}</title>
      <ContentLayout page={pageData} classes="faq-page">
        {/* The page.html is actually markdown when viewing the page from the netlify CMS,
              so we must use the ReactMarkdown component to parse the markdown in that case  */}
          {page.bodyIsMarkdown ? (
            <ReactMarkdown source={page.html} />
          ) : (
            <HTMLContent content={page.html} />
          )}
          {data.items.map((item, i) => (
            <Card item={item} key={`faq-${i}`}/>
          ))}
      </ContentLayout>
    </main>
  );
};

const Card = props => {
  const { item } = props
  return (
    <div className="col p-4 card-item">
      <div className="col-12">
        <h3 className="mb-3">{item.title}</h3>
        <MarkdownToHtml content={item.text}/>
      </div>
    </div>
  )
}

const FaqPage = ({ data }) => {
  const isBrowser = typeof window !== "undefined"
  const pathName = isBrowser ? window.location.pathname: ""

  const { markdownRemark: page } = data;
  const {
    frontmatter: {
      seo: { title: seoTitle, description: seoDescription },
    },
  } = page;

  return (
    <>
      <SEO
        title={seoTitle}
        desc={seoDescription}
        pathname={pathName}
      />
      <FaqPageTemplate page={{ ...page, bodyIsMarkdown: false }} />
    </>
  );
};

FaqPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default FaqPage;

export const faqPageQuery = graphql`
  query FaqPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        subtitle
        seo {
          title
          description
        }
        items {
          title
          text
        }
      }
    }
  }
`;